import React, { useEffect } from "react";
import LoginForm from "../components/LoginForm";
import Loader from "../components/Loader";

import { useAuth } from "../services/hooks/useAuth";
import { usePageTransition } from "../services/hooks/usePageTransition";
import { useRedirect } from "../services/hooks/useRedirect";
import { useGlobal } from "../services/hooks/useGlobal";

export default function login() {
  const { startTransition, completeTransition } = usePageTransition();
  const { user, authStatus, login, loginError, loginStatus } = useAuth();
  const { redirect } = useRedirect();

  const { setPageTitle } = useGlobal();

  useEffect(() => {
    setPageTitle({
      pageTitle: `Login`,
    });

    return () => {
      setPageTitle({
        pageTitle: null,
      });
    };
  }, []);

  const onLogin = (data) => {
    login(data);
  };

  useEffect(() => {
    completeTransition();

    return () => {
      startTransition();
    };
  }, []);

  useEffect(() => {
    if (user) {
      redirect("/account");
    }
  }, [user]);

  if (authStatus !== "ready" || user) {
    return <Loader fixedCenter />;
  }

  return (
    <React.Fragment>
      <div className="container w-md-50">
        <div className="card card-lg">
          <div className="card-body">
            <h3 className="mb-7 text-center">Sign Into Your Account</h3>

            <LoginForm
              onLogin={onLogin}
              loginError={loginError}
              loginStatus={loginStatus}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
